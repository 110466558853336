import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  LanguageService
} from '@spartacus/core';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { VscaAuthService } from '../../../../core/auth/user-auth/facade/vsca-auth.service';
import { DataLayerService } from '../../../shared/services/data-layer.service';
import { SharedService } from '../../../shared/services/shared.service';
import {
  NumberEight,
  NumberThree,
  NumberTwo
} from '../../../shared/utils/constants/global.constants';
import { CustomRegistrationService } from '../../custom-registration.service';
import {
  globalErrorMsgEng,
  globalErrorMsgFr,
  siteLanguagesEng
} from '../../registration.constants';

const alternateEmail = 'alternateEmailInfo.alternateEmail';
const confirmAlternateEmail = 'alternateEmailInfo.confirmAlternateEmail';
const emailRegexExp =
  '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent implements OnInit, OnDestroy {
  @Input() formDetails: FormGroup;

  userInfoForm: FormGroup;
  errorMsg = false;
  isEmailConsent: boolean[] = [true, false];
  isSmsConsent: boolean[] = [true, false];
  passwordQuestions: any[] = [];
  contactRole: any[] = [];
  altEmailSection = false;
  isPasswordValid = true;
  accountData: any;
  janrainUserData: any;
  indirectRegistrationData: any;
  cities: any;
  errorMessageText: string = globalErrorMsgEng;
  passwordQuestionSub: Subscription;
  userContactRoleSub: Subscription;
  accountNoSub: Subscription;
  janrainUserSub: Subscription;
  indirectRegistrationDataSub: Subscription;
  userRegSub: Subscription;
  emailRegex =
    /^([A-Za-z\d\._-]+)@([A-Za-z\d\._-]+)\.([A-Za-z\.]{2,8})(\.[A-Za-z\.]{2,8})?$/g;
  confirmationMessage: { title: string; wholesalerFlag: boolean; content: string } = {
    title: '',
    wholesalerFlag: false,
    content: '',
  };
  isUsernameExists = false;
  isEmailExists = false;
  errorMessages: string[] = [
    'b2bCustomer.register.userName.exists',
    'b2bCustomer.register.email.exists',
  ];
  EMAIL_KEY = 'emailInfo.email'
  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly auth: VscaAuthService,
    private readonly globalMessage: GlobalMessageService,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.languageService.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.accountNoSub = this.accountRegService
      .getAccountData('anonymous', this.formDetails.get('accountNumber').value)
      .subscribe((res) => {
        if (res) {
          this.accountData = JSON.parse(JSON.stringify(res));
          this.cdr.detectChanges();
        }
      });
    this.accountRegService.getPasswordRecoveryQuestions({
      enumerationType: 'HintQuestions',
      fields: 'DEFAULT',
    });
    this.accountRegService.getContactRole({
      enumerationType: 'UserRole',
      fields: 'DEFAULT',
    });
    const maxlengthChar = 256;
    const maxlengthTwelve = 12;
    const charlengthTen = 10;
    this.userInfoForm = new FormGroup({
      userInfoDetails: new FormGroup(
        {
          userName: new FormControl('', [
            Validators.required,
            Validators.pattern('[a-zA-Z0-9]+$'),
            this.validateUsernamePasswordAsAccountNo.bind(this),
            Validators.minLength(1),
            Validators.maxLength(charlengthTen),
          ]),
          password: new FormControl('', [
            Validators.required,
            this.validatePassword.bind(this),
            this.validateUsernamePasswordAsAccountNo.bind(this),
          ]),
          confirmPassword: new FormControl('', [
            Validators.required,
            this.validatePassword.bind(this),
          ]),
        },
        {
          validators: [
            this.pwdMatchValidator,
            this.validateUsernameAndPasswordNotSame,
          ],
        }
      ),
      passwordQuestion: new FormControl(null, [Validators.required]),
      passwordAnswer: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(maxlengthChar),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(maxlengthChar),
      ]),
      role: new FormControl(null, [Validators.required]),
      emailInfo: new FormGroup(
        {
          email: new FormControl('', [
            Validators.required,
            Validators.pattern(emailRegexExp),
          ]),
          confirmEmail: new FormControl('', [
            Validators.required,
            Validators.pattern(emailRegexExp),
          ]),
        },
        { validators: [this.emailMatchValidator] }
      ),
      emailConsent: new FormControl(this.isEmailConsent[1], [
        Validators.required,
      ]),

      alternateEmailInfo: new FormGroup({
        alternateEmail: new FormControl(''),
        confirmAlternateEmail: new FormControl(''),
      }),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^([0-9]{8,10})$'),
        Validators.maxLength(maxlengthTwelve),
      ]),
      sanofiRegistrationNo: new FormControl('', [
        Validators.pattern('^(WDZM|WDZP)[0-9]{8,8}\\S{0,}$'),
      ]),
      address: new FormControl('', []),
      city: new FormControl(null, [Validators.required]),
      smsConsent: new FormControl(this.isSmsConsent[1], [
        Validators.required,
      ]),
      termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
      privateEmailConsent: new FormControl(false, [Validators.requiredTrue]),
    });

    if (this.formDetails.get('wholesalerFlag')?.value){
      this.janrainUserSub = this.accountRegService
        .getJanrainUserData(this.formDetails.get(this.EMAIL_KEY).value)
        .subscribe((res) => {
          if (res) {
            this.janrainUserData = JSON.parse(JSON.stringify(res));
            if (!!this.janrainUserData && !!this.janrainUserData?.userName)
            {
              this.userInfoForm.get('userInfoDetails.userName').clearValidators();
            }
            this.cdr.detectChanges();
          }
        });
      this.indirectRegistrationDataSub = this.accountRegService
        .getIndirectRegistrationData()
        .subscribe((res) => {
          if (res) {
            this.indirectRegistrationData = JSON.parse(JSON.stringify(res));
            if (!!this.indirectRegistrationData.cities)
            {
              this.cities = this.indirectRegistrationData.cities;
              this.cities.sort((a, b) => a.name.localeCompare(b.name));
            }
            this.cdr.detectChanges();
          }
        });
    }
    if (this.formDetails.get('wholesalerFlag')?.value){
      this.userInfoForm.get('userInfoDetails.password').clearValidators();
      this.userInfoForm.get('userInfoDetails.confirmPassword').clearValidators();
      this.userInfoForm.get('userInfoDetails').clearValidators();
      this.userInfoForm.get(this.EMAIL_KEY).clearValidators();
      this.userInfoForm.get('emailInfo.confirmEmail').clearValidators();
      this.userInfoForm.get('emailInfo').clearValidators();
      this.userInfoForm.get('role').clearValidators();
    }
    else {
      this.userInfoForm.get('sanofiRegistrationNo').clearValidators();
      this.userInfoForm.get('address').clearValidators();
      this.userInfoForm.get('city').clearValidators();
      this.userInfoForm.get('privateEmailConsent').clearValidators();
    }

    this.passwordQuestionSub =
      this.accountRegService.passwordRecoveryQuestionsData$.subscribe((res) => {
        if (res !== false) {
          this.passwordQuestions = res;
        }
      });

    this.userContactRoleSub = this.accountRegService.contactRoleData$.subscribe(
      (response) => {
        if (response !== false) {
          const roles = _.filter(response,function(role) {
            return role.code != 'OTHER' && role.code != '62';
          });
          this.contactRole = roles;
        }
      }
    );

    this.userInfoForm.get('emailConsent').valueChanges.subscribe((value) => {
      if (value === false) {
        this.deleteAltEmailSection();
      }
    });
  }

  setUserDetails(formDta, userDetails): any {
    for (const key in formDta) {
      if (
        key !== 'userInfoDetails' &&
        key !== 'emailInfo' &&
        key !== 'alternateEmailInfo'
      ) {
        userDetails[key] = formDta[key];
      }
    }
    return userDetails;
  }

  setAccountDetails(formDta, userDetails): any {
    for (const key in formDta) {
      if (
        key !== 'userInfoDetails' &&
        key !== 'emailInfo'
      ) {
        userDetails[key] = formDta[key];
      }
    }
    return userDetails;
  }

  submitUserInfo() {
    this.userInfoForm.markAllAsTouched();
    if (this.userInfoForm.valid) {
      let userDetails = {};
      const formDta = { ...this.userInfoForm.value };
      if (!!formDta) {
        userDetails = this.setUserDetails(formDta, userDetails);
      }
      let userData: any;
      if (this.formDetails.get('wholesalerFlag')?.value) {
        const accountDta = { ...this.formDetails.value };
        let accountDetails = {};
        accountDetails = this.setAccountDetails(accountDta, accountDetails);
        userData = {
          ...accountDetails,
          ...userDetails,
          ...this.userInfoForm.get('userInfoDetails').value,
          ...this.formDetails.get('userInfoDetails').value,
          ...this.formDetails.get('emailInfo').value,
          ...this.userInfoForm.get('alternateEmailInfo').value,
        };
      }
      else {
        userData = {
          ...this.formDetails.value,
          ...userDetails,
          ...this.userInfoForm.get('userInfoDetails').value,
          ...this.userInfoForm.get('emailInfo').value,
          ...this.userInfoForm.get('alternateEmailInfo').value,
        };
      }
      this.confirmationMessage.title = userData.firstName;
      this.confirmationMessage.wholesalerFlag = this.formDetails.get('wholesalerFlag')?.value;
      this.createNewUser(userData);
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  createNewUser(userData) {
    this.userRegSub = this.accountRegService
      .postUserRegistrationInfo(userData)
      .subscribe(
        (res) => {
          if (res == null) {
            
            if (!this.formDetails.get('wholesalerFlag')?.value) {
              this.dataLayerService.registrationDataLayer();
              this.accountRegService.loginWithCredentials(
                userData.userName,
                userData.password
              );
            }else{
              //userData.userName
              this.dataLayerService.pharma_registrationDataLayer();
            }
            const completeUserData = {
              header: 'confirmation',
              msgData: this.confirmationMessage,
            };
            !!localStorage.getItem('userConfirmationData')
              ? localStorage.removeItem('userConfirmationData')
              : null;
            localStorage.setItem(
              'userConfirmationData',
              JSON.stringify(completeUserData)
            );
            this.sharedService.setSessionStorage(
              'userConfirmationData',
              completeUserData
            );
            this.router.navigate(['/customer-register/confirm']);
          }
        },
        (error) => {
          if (!!error?.error?.errors && error?.error?.errors.length > 0) {
            for (const errorItem of error?.error?.errors) {
              if (errorItem?.message === this.errorMessages[0]) {
                this.isUsernameExists = true;
                this.userInfoForm
                  .get('userInfoDetails.userName')
                  .markAsUntouched();
              } else if (errorItem?.message === this.errorMessages[1]) {
                this.isEmailExists = true;
                this.userInfoForm.get(this.EMAIL_KEY).markAsUntouched();
              } else {
                this.isUsernameExists = false;
                this.isEmailExists = false;
              }
            }
            this.cdr.detectChanges();
            if (this.isEmailExists || this.isUsernameExists) {
              window.scroll(0, 0);
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              this.globalMessage.add(
                this.errorMessageText,
                GlobalMessageType.MSG_TYPE_ERROR
              );
            }
          }
        }
      );
  }

  addAlternateEmail() {
    this.altEmailSection = true;
    this.userInfoForm
      .get('alternateEmailInfo')
      .setValidators(this.alternateEmailMatchValidator);
    this.userInfoForm
      .get(alternateEmail)
      .setValidators([Validators.required, Validators.pattern(emailRegexExp)]);
    this.userInfoForm
      .get(confirmAlternateEmail)
      .setValidators([Validators.required, Validators.pattern(emailRegexExp)]);
    this.userInfoForm.get(alternateEmail).updateValueAndValidity();
    this.userInfoForm.get(confirmAlternateEmail).updateValueAndValidity();
  }

  deleteAltEmailSection() {
    this.userInfoForm.patchValue(
      {
        alternateEmailInfo: {
          alternateEmail: null,
          confirmAlternateEmail: null,
        },
      },
      { emitEvent: false }
    );
    this.userInfoForm.get('alternateEmailInfo').clearValidators();
    this.userInfoForm.get(alternateEmail).clearValidators();
    this.userInfoForm.get(confirmAlternateEmail).clearValidators();
    this.userInfoForm.get(alternateEmail).markAsUntouched();
    this.userInfoForm.get(alternateEmail).markAsPristine();
    this.userInfoForm.get(confirmAlternateEmail).markAsPristine();
    this.userInfoForm.get(confirmAlternateEmail).markAsUntouched();
    this.userInfoForm.get(alternateEmail).updateValueAndValidity();
    this.userInfoForm.get(confirmAlternateEmail).updateValueAndValidity();
    this.userInfoForm.get('alternateEmailInfo').updateValueAndValidity();
    this.altEmailSection = false;
    this.cdr.detectChanges();
  }

  validateUsernamePasswordAsAccountNo(field: FormControl): {
    [s: string]: boolean;
  } {
    const val = field.value;
    if (!!val && val.indexOf(this.formDetails.get('accountNumber').value) !== -1) {
      return { sameAsaccountNo: true };
    } else {
      return null;
    }
  }

  emailMatchValidator(frm: FormGroup) {
    if (!!frm) {
      if (frm.get('email').value !== frm.get('confirmEmail').value) {
        return { emailNotMatch: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  alternateEmailMatchValidator(frm: FormGroup) {
    if (!!frm) {
      if (
        frm.get('alternateEmail').value !==
        frm.get('confirmAlternateEmail').value
      ) {
        return { altEmailNotMatch: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  pwdMatchValidator(frm: FormGroup) {
    if (!!frm) {
      return frm.get('password').value === frm.get('confirmPassword').value
        ? null
        : { mismatch: true };
    } else {
      return null;
    }
  }

  validateUsernameAndPasswordNotSame(frm: FormGroup): { [s: string]: boolean } {
    if (!!frm) {
      if (frm.get('userName').value === frm.get('password').value) {
        return { userNamePasswordMatch: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  validatePassword(password: FormControl): { [s: string]: boolean } {
    // Initialize counter to zero
    let counter = 0;
    let lengthCounterFlag = false;
    let nonSpecialCounterFlag = false;
    let specialCounterFlag = false;
    const str = password.value;

    // On each test that is passed, increment the counter
    if (/[a-z]/.test(str)) {
      // If string contain at least one lowercase alphabet character
      counter++;
    }
    if (/[A-Z]/.test(str)) {
      counter++;
    }
    if (/[0-9]/.test(str)) {
      counter++;
    }
    if (/[^a-zA-Z0-9]/.test(str)) {
      nonSpecialCounterFlag = true;
    }

    if (str.length >= NumberEight) {
      lengthCounterFlag = true;
    }

    const specialChars = this.getAllSpecialChars(str);

    if (nonSpecialCounterFlag && /^[*$%!)(&^]+$/.test(specialChars)) {
      specialCounterFlag = true;
    }
    // Check if at least three rules are satisfied
    this.checkAllRulesForPassword(
      counter,
      lengthCounterFlag,
      nonSpecialCounterFlag,
      specialCounterFlag
    );

    if (!this.isPasswordValid) {
      return { isPasswordInvalid: true };
    } else {
      return null;
    }
  }

  checkAllRulesForPassword(
    counter,
    lengthCounterFlag,
    nonSpecialCounterFlag,
    specialCounterFlag
  ) {
    if (
      (counter === NumberThree &&
        lengthCounterFlag &&
        !nonSpecialCounterFlag) ||
      (counter >= NumberTwo &&
        lengthCounterFlag &&
        nonSpecialCounterFlag &&
        specialCounterFlag)
    ) {
      this.isPasswordValid = true;
    } else {
      this.isPasswordValid = false;
    }
  }

  getAllSpecialChars(str: string): string {
    const strAr = str.split('');
    const noSpecialChars = '';
    const resArr = [];
    for (const strItem of strAr) {
      if (/[^a-zA-Z0-9]/.test(strItem)) {
        resArr.push(strItem);
      }
    }
    if (resArr.length > 0) {
      return resArr.join('');
    } else {
      return noSpecialChars;
    }
  }

  ngOnDestroy() {
    this.passwordQuestionSub?.unsubscribe();
    this.userContactRoleSub?.unsubscribe();
    this.accountNoSub?.unsubscribe();
    this.janrainUserSub?.unsubscribe();
    this.indirectRegistrationDataSub?.unsubscribe();
    this.userRegSub?.unsubscribe();
  }
}
