import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss']
})
export class AccountHeaderComponent {
  @Input() selectedHeader: string;
  @Input() tableCategory: string;
}
