import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbModal,
  NgbPopover,
  NgbPopoverConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { LanguageService, OccEndpointsService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { CustomAddToCartService } from '../../custom-add-to-cart/custom-add-to-cart.service';
import { ProductDetailsComponent } from '../../custom-add-to-cart/custom-add-to-cart/product-details/product-details.component';
import { DataLayerService } from '../../shared/services/data-layer.service';
import { ExcelDownloadService } from '../../shared/services/excel-download.service';
import { OrderHistoryService } from '../order-history.service';
declare const require: any;

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  currentPopover: NgbPopover;

  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  sessionStorage = sessionStorage;
  accountType: string = sessionStorage.getItem('accountType');
  orderDetailsData: any;
  userData: any;
  orderCode: any;
  baseUrl: string;
  saveCartName: string;
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  modalReference: any;
  cartId: string;
  childCartID: any;
  standardCartDetails: any;
  errorMsg: string;
  cartItems: any;
  cartAllItems: any;
  userSubscription: Subscription;
  orderCartSubscription: Subscription;
  orderDetailSubscription: Subscription;
  addToCartSubscription: Subscription;
  userAccountSubscription: Subscription;
  userDetailSubscription: Subscription;
  saveOrderSubscription: Subscription;
  invalidOrderSubscription: Subscription;
  createCartSubscription: Subscription;
  addCartDetailsSubscription: Subscription;
  deleteOrderSubscription: Subscription;
  currentLangSub: Subscription;
  currentLang: String;
  productID: any;
  qty: any;
  productName: any;
  billToLocation: any;
  shipToLocation: any;
  whs: any;
  excelCLicked: boolean = false;
  excelName: any;
  hasOrderCancelled: boolean = false;
  orderContainsWholesalerProducts = false;
  showDisclaimer = false;

  constructor(
    private readonly modalService: NgbModal,
    private readonly user: UserAccountFacade,
    private readonly orderService: OrderHistoryService,
    private readonly route: ActivatedRoute,
    private readonly cd: ChangeDetectorRef,
    private readonly occEndPoint: OccEndpointsService,
    private readonly addToCart: CustomAddToCartService,
    private readonly router: Router,
    public excelService: ExcelDownloadService,
    private readonly config: NgbPopoverConfig,
    private readonly sanitizer: DomSanitizer,
    private languageService: LanguageService,
    private readonly dataLayerService: DataLayerService
  ) {
    config.autoClose = 'outside';
  }

  ngOnInit(): void {
    const url = this.router.url;
    const orderId = url.match(/[^\/]+$/)[0];
    // this.orderCode = this.route.snapshot.params.orderCode;
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.orderService.orderDetails.next(null);
    this.userSubscription = this.user.get().subscribe((user) => {
      if (user) {
        this.userData = user;
        this.orderService.getOrderDetails(this.userData.userName, orderId);
        this.getUserAccountData(this.userData.userName);
        if (this.userData && this.userData !== null) {
          this.orderService.getCarts(this.userData?.userName);
        }
      }
    });
    this.orderCartSubscription = this.orderService.orderCartItems$.subscribe(
      (orderItem) => {
        if (orderItem) {
          this.cartAllItems = orderItem?.carts;
          this.cartAllItems = this.cartAllItems.filter(
            (item) => item?.saveTime === undefined
          );
          this.cartAllItems = this.cartAllItems[this.cartAllItems?.length - 1];
          this.cartId = this.cartAllItems?.code;
          if (this.cartId !== undefined) {
            this.cartChecking();
          } else {
            this.standardCartDetails = [];
          }
        }
      }
    );

    this.orderDetailSubscription = this.orderService.orderDetails$.subscribe(
      (res) => {
        this.hasOrderCancelled = false;
        if (res) {
          this.orderDetailsData = res;
          const createDate = this.orderDetailsData.created.split('T');
          this.orderDetailsData.created = createDate[0];
          this.orderDetailsData.entries.forEach((element) => {
            if (!this.showDisclaimer && element?.wholesalerProduct) {
              this.showDisclaimer = true;
              this.orderContainsWholesalerProducts = true;
            }
            const imagesFormat = _.clone(
              _.findWhere(element.product?.images, { format: 'thumbnail' })
            );
            if (imagesFormat) {
              imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
              element.PRIMARY = imagesFormat;
            }

            // const data = this.orderDetailsData.consignments.filter(item =>
            //   element.product.code === item.entries[0].prodCode
            // );

            element.prodConsignments = [];
            let statusDate;
            this.orderDetailsData.consignments.forEach((consignment) => {
              if (consignment.entries.length > 0) {
                if (
                  consignment.statusDate != null ||
                  consignment.statusDate != undefined
                ) {
                  statusDate = consignment.statusDate;
                }
                consignment.entries.forEach((entry) => {
                  if (entry.orderEntry.product.code == element.product.code) {
                    if (entry.length !== 0) {
                      entry.statusDate = statusDate;
                      element.prodConsignments.push(entry);
                    }
                  }
                });
              }
            });

            // const data = this.orderDetailsData.consignments;
            // if (data.length !== 0) {
            //   element.prodConsignments = data;
            // }

            this.orderDetailsData.consignments.forEach((pce) => {
              const res = pce.entries;
              res.forEach((consignmentEntries) => {
                if (consignmentEntries.quantityDeclined > 0) {
                  this.hasOrderCancelled = true;
                }
              });
            });
          });
          this.cd.detectChanges();
        }
      }
    );
  }
  cartChecking() {
    this.addToCart.getCarts(this.cartId, this.userData?.userName);
    this.addToCartSubscription = this.addToCart.cartItems$.subscribe(
      (cartData: any) => {
        if (cartData) {
          let stdCartDetails = [];
          stdCartDetails = cartData?.childCarts;
          if (stdCartDetails) {
            stdCartDetails = stdCartDetails.filter(
              (item) => item.cartType === this.orderDetailsData.cartType
            );
            if (stdCartDetails?.length !== 0) {
              this.standardCartDetails = Object.assign({}, ...stdCartDetails);
            }
          }
        }
      }
    );
  }
  getUserAccountData(userId): void {
    this.addToCart.getUserAccountDataFromDb(userId);
    this.userAccountSubscription = this.addToCart.userAccountData.subscribe(
      (userData) => {
        if (
          userData.res != null &&
          userData.res !== undefined &&
          userData.res?.length !== 0
        ) {
          this.usergetDataObj = userData.res.defaultB2BUnit;
          const accountId = this.usergetDataObj.uid;
          this.addToCart.getUserAccountDetailsDataFromDb(userId, accountId);
          this.userDetailSubscription =
            this.addToCart.userAccountDetails.subscribe((userDataget) => {
              if (userDataget) {
                this.getuserData = userDataget.res.shippingAddresses;
                this.searchedClientList = userDataget?.res?.shippingAddresses;
                this.selectedData = this.getuserData[0];
                this.cd.detectChanges();
              }
            });
        }
      }
    );
  }
  openProductDetails(productDetails) {
    if (productDetails.basePrice.formattedValue !== undefined) {
      productDetails.product.price = {
        value: productDetails.basePrice.formattedValue,
      };
    }
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = '';
    modalRef.componentInstance.unitSection = 'NoUnits';
  }
  openModal(content) {
    if (
      this.standardCartDetails !== undefined &&
      this.standardCartDetails?.length !== 0
    ) {
      this.modalReference = this.modalService.open(content, {
        size: 'md',
        windowClass: 'modal-md',
      });
    } else {
      this.getCartId('addCart');
    }
  }
  toggle(popover) {
    this.currentPopover = popover;
    if (popover.isOpen()) {
      popover.close();
      this.errorMsg = '';
    } else {
      popover.open();
    }
  }
  existingCart() {
    this.modalReference.close();
  }
  excelDownload() {
    this.currentLangSub = this.languageService.getActive().subscribe((res) => {
      this.currentLang = res;
    });
    this.excelCLicked = true;
    const excelArray = [];

    if (this.currentLang && this.currentLang !== undefined) {
      switch (this.currentLang) {
        case 'en':
          this.productID = 'Product ID';
          this.qty = 'Qty (unit/doses)';
          this.productName = 'Product Name';
          this.billToLocation = 'Bill To Location';
          this.shipToLocation = 'Ship To Location';
          if (this.orderContainsWholesalerProducts) {
            this.whs = 'WHS';
          }
          this.excelName = 'Order Details';
          break;
        default:
          this.productID = 'Numéro de produit';
          this.qty = 'Unités/doses';
          this.productName = 'Nom de produit';
          this.billToLocation = "Facturer à l'adresse";
          this.shipToLocation = "Envoyer à l'adresse";
          if (this.orderContainsWholesalerProducts) {
            this.whs = 'WHS';
          }
          this.excelName = 'Page des détails de la commande';
          break;
      }
    }

    this.orderDetailsData.entries.forEach((element) => {
      const doses = element.quantity * element.product.quantityPerUnit;
      const obj = {
        [this.productID]: element.product.code,
        [this.qty]: `${element.quantity}/${doses}`,
        [this.productName]: element.product.name,
        [this.billToLocation]:
          this.orderDetailsData.billingAddress.formattedAddress,
        [this.shipToLocation]:
          this.orderDetailsData.deliveryAddress.formattedAddress,
      };
      if (this.orderContainsWholesalerProducts) {
        obj[this.whs] = element.wholesalerDisplayName;
      }
      excelArray.push(obj);
    });
    this.excelService.exportAsExcelFile(excelArray, this.excelName, true);
  }
  saveOrder() {
    let code;
    if (
      this.orderDetailsData?.guid !== undefined &&
      this.orderDetailsData?.guid !== ''
    ) {
      code = this.orderDetailsData?.guid;
    } else {
      code = this.orderDetailsData?.code;
    }
    this.orderService.saveCartOrderDetails(
      this.userData.userName,
      code,
      this.saveCartName
    );
    this.saveOrderSubscription =
      this.orderService.saveCartOrderSuccess$.subscribe((res) => {
        if (res) {
          this.currentPopover.close();
        }
      });
    this.invalidOrderSubscription =
      this.orderService.invalidOrderCartName$.subscribe((res) => {
        if (res) {
          this.errorMsg = _.pluck(res?.error?.errors, 'message');
          this.errorMsg =
            this.errorMsg === 'This field is required.'
              ? 'Cart name cannot be blank'
              : this.errorMsg;
        }
      });
  }
  getCartId(value) {
    if (this.cartAllItems === undefined) {
      this.orderService.createCart(this.userData?.userName);
      this.createCartSubscription =
        this.orderService.createdOrderCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.orderService.getCarts(this.userData?.userName);
            this.addToCarts(value);
          }
        });
    } else {
      if (this.cartId === undefined) {
        this.cartId = this.cartAllItems?.code;
      }
      this.addToCarts(value);
    }
  }
  addToCarts(value) {
    const data = [];
    this.orderDetailsData.entries.forEach((element) => {
      data.push({
        entryNumber: 0,
        productCode: element.product.code,
        quantity: element.quantity,
      });
    });
    const objArr = {
      addToCartForm: data,
    };
    this.orderService.addToCart(this.userData.userName, objArr, this.cartId);
    this.addCartDetailsSubscription =
      this.orderService.addCartDetails$.subscribe((res) => {
        if (res !== false && res !== null) {
          this.dataLayerService.addCartDataLayer(
            res,
            'standard process',
            '',
            'orderdetail'
          );
          if (value === 'clearCart') {
            this.modalReference.close();
          }
          this.addToCart.getAllCarts(this.userData?.userName);
          this.router.navigate(['/cart']);
        }
      });
  }
  clearChildCart(): void {
    this.orderService.clearCart(
      this.standardCartDetails.code,
      this.userData.userName
    );
    this.deleteOrderSubscription =
      this.orderService.deleteOrderItems$.subscribe((res) => {
        if (res) {
          this.getCartId('clearCart');
          this.orderService.deleteOrderItems.next(false);
        }
      });
  }

  getProductName(name) {
    return name ? this.sanitizer.bypassSecurityTrustHtml(name) : '';
  }

  getDescription(description) {
    return description
      ? this.sanitizer.bypassSecurityTrustHtml(description)
      : '';
  }
  print() {
    window.print();
  }
  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
    this.orderCartSubscription?.unsubscribe();
    this.orderDetailSubscription?.unsubscribe();
    this.addToCartSubscription?.unsubscribe();
    this.userAccountSubscription?.unsubscribe();
    this.userDetailSubscription?.unsubscribe();
    this.saveOrderSubscription?.unsubscribe();
    this.invalidOrderSubscription?.unsubscribe();
    this.createCartSubscription?.unsubscribe();
    this.addCartDetailsSubscription?.unsubscribe();
    this.deleteOrderSubscription?.unsubscribe();
    this.currentLangSub?.unsubscribe();
  }

  downloadFile(downloadUrl, name) {
    if (downloadUrl && downloadUrl.url) {
      const pdfUrl = `${this.baseUrl}${downloadUrl.url}`;
      const pdfName = `${name}`;
      const FileSaver = require('file-saver');
      FileSaver.saveAs(pdfUrl, pdfName);
    }
  }

  closeDisclaimerAlert() {
    this.showDisclaimer = false;
  }
}
