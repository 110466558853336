import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
} from '@spartacus/core';
import { Subscription } from 'rxjs';
import { CustomRegistrationService } from '../../custom-registration.service';
import {
  globalErrorMsgEng,
  globalErrorMsgFr,
  siteLanguagesEng,
} from '../../registration.constants';
import {NumberEight, NumberThree, NumberTwo} from "../../../shared/utils/constants/global.constants";
import {VscaAuthService} from '../../../../core/auth/user-auth/facade/vsca-auth.service';


const emailRegexExp =
  '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$';
const taxNumberRegexExp = '[0-9a-zA-Z]+$';
const accountNumberRegexExp = '[0-9]+$';

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss'],
})
export class AccountInformationComponent implements OnInit, OnDestroy {
  accountInfoForm: FormGroup;
  errorMsg = false;
  infoMsg = false;
  errorTypes: string[] = ['b2bCustomer.register.accountNumber.invalidType', 'b2bCustomer.register.email.exists', 'b2bCustomer.register.akamai.email.exists', 'b2bCustomer.register.akamai.login.failed', 'b2bCustomer.register.akamai.password.empty', 'b2bCustomer.register.accountType.invalidType'];
  billToShipError = false;
  accountTypeError = false;
  errorMessageText: string = globalErrorMsgEng;
  accountInfoSub: Subscription;
  taxAndAccountNumberValidatorSub: Subscription;
  isEmailExists = false;
  isPasswordEmptyInAkamai = false;
  isIndirectB2BUnitValidated = false;
  akamaiPasswordValidationError = false;
  isPasswordValid = true;
  userAuthDetails: any;
  janrainUserData: any;
  skipAkamaiValidation = false;
  resetPasswordEmailSent = false;
  captchaResp: string
  EMAIL_KEY = 'emailInfo.email';
  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly globalMessage: GlobalMessageService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly auth: VscaAuthService,
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    const maxlengthChar = 20;
    const maxlengthFive = 5;
    this.languageService.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.accountInfoForm = new FormGroup({
      accountNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]+$'),
        Validators.maxLength(maxlengthChar),
      ]),
      wholesalerFlag: new FormControl(false),
      registeredAkamaiUser: new FormControl(false),
      postalCode: new FormControl('', [
        Validators.required,
        Validators.pattern('^([0-9]{5})$'),
        Validators.maxLength(maxlengthFive),
      ]),
      emailInfo: new FormGroup(
        {
          email: new FormControl('', [
            Validators.required,
            Validators.pattern(emailRegexExp),
          ]),
          confirmEmail: new FormControl('', [
            Validators.required,
            Validators.pattern(emailRegexExp),
          ]),
        },
        { validators: [this.emailMatchValidator] }
      ),
      userInfoDetails: new FormGroup(
        {
          password: new FormControl('', []),
          confirmPassword: new FormControl('', []),
        },
        {
          validators: [],
        }
      ),
      recaptchaReactive: new FormControl(null, Validators.required),
    });
    this.taxAndAccountNumberValidatorSub = this.accountInfoForm
      .controls
      .wholesalerFlag
      .valueChanges
      .subscribe(active => {
        this.accountInfoForm.controls.accountNumber.clearValidators();
        if (active === true) {
          this.accountInfoForm.controls.accountNumber.setValidators([
              Validators.required,
              Validators.pattern(taxNumberRegexExp),
              Validators.maxLength(maxlengthChar)
            ]);
        } else {
          this.accountInfoForm.controls.accountNumber.setValidators([
            Validators.required,
            Validators.pattern(accountNumberRegexExp),
            Validators.maxLength(maxlengthChar)
          ]);
        }
        this.accountInfoForm.controls.accountNumber.updateValueAndValidity();
      });
    this.userAuthDetails = this.isEmptyObject(this.auth.userAuthDetails) ? undefined : this.auth.userAuthDetails;
    this.radioButtonIsWholesalerFromAkamaiIfExist();
  }
  
  resolved(captchaResponse: string) {
    this.captchaResp = captchaResponse;
  }

  validatePassword(password: FormControl): { [s: string]: boolean } {
    // Initialize counter to zero
    let counter = 0;
    let lengthCounterFlag = false;
    let nonSpecialCounterFlag = false;
    let specialCounterFlag = false;
    const str = password.value;

    // On each test that is passed, increment the counter
    if (/[a-z]/.test(str)) {
      // If string contain at least one lowercase alphabet character
      counter++;
    }
    if (/[A-Z]/.test(str)) {
      counter++;
    }
    if (/[0-9]/.test(str)) {
      counter++;
    }
    if (/[^a-zA-Z0-9]/.test(str)) {
      nonSpecialCounterFlag = true;
    }

    if (str.length >= NumberEight) {
      lengthCounterFlag = true;
    }

    const specialChars = this.getAllSpecialChars(str);

    if (nonSpecialCounterFlag && /^[*$%!)(&^]+$/.test(specialChars)) {
      specialCounterFlag = true;
    }
    // Check if at least three rules are satisfied
    this.checkAllRulesForPassword(
      counter,
      lengthCounterFlag,
      nonSpecialCounterFlag,
      specialCounterFlag
    );

    if (!this.isPasswordValid) {
      return { isPasswordInvalid: true };
    } else {
      return null;
    }
  }

  checkAllRulesForPassword(
    counter,
    lengthCounterFlag,
    nonSpecialCounterFlag,
    specialCounterFlag
  ) {
    if (
      (counter === NumberThree &&
        lengthCounterFlag &&
        !nonSpecialCounterFlag) ||
      (counter >= NumberTwo &&
        lengthCounterFlag &&
        nonSpecialCounterFlag &&
        specialCounterFlag)
    ) {
      this.isPasswordValid = true;
    } else {
      this.isPasswordValid = false;
    }
  }

  getAllSpecialChars(str: string): string {
    const strAr = str.split('');
    const noSpecialChars = '';
    const resArr = [];
    for (const strItem of strAr) {
      if (/[^a-zA-Z0-9]/.test(strItem)) {
        resArr.push(strItem);
      }
    }
    if (resArr.length > 0) {
      return resArr.join('');
    } else {
      return noSpecialChars;
    }
  }

  pwdMatchValidator(frm: FormGroup) {
    if (!!frm) {
      return frm.get('password').value === frm.get('confirmPassword').value
        ? null
        : { mismatch: true };
    } else {
      return null;
    }
  }


  get emailInfoFormGroup() {
    return this.accountInfoForm.get('emailInfo') as FormGroup;
  }

  get userInfoDetailsGroup() {
    return this.accountInfoForm.get('userInfoDetails') as FormGroup;
  }

  emailMatchValidator(frm: FormGroup) {
    if (!!frm) {
       if (frm.get('email').value !== frm.get('confirmEmail').value) {
        return { emailNotMatch: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }


  validateAccountInfo() {
    if (this.accountInfoForm.get('wholesalerFlag').value && (this.isIndirectB2BUnitValidated  || this.skipAkamaiValidation === true)) {
      this.validateIndirectAccountInAkamai();
    }
    else if (this.accountInfoForm.get('wholesalerFlag').value && !this.isIndirectB2BUnitValidated){
        this.validateIndirectAccountInfo();
      }
    else {
      this.validateDirectAccountInfo();
    }
  }

  validateIndirectAccountInfo() {
    this.accountInfoForm.markAllAsTouched();
    this.accountInfoForm.controls.emailInfo.clearValidators();
    this.emailInfoFormGroup.controls.email.setValidators([Validators.required, Validators.pattern(emailRegexExp)]);
    this.emailInfoFormGroup.controls.confirmEmail.clearValidators();
    this.accountInfoForm.controls.emailInfo.updateValueAndValidity();
    this.emailInfoFormGroup.controls.email.updateValueAndValidity();
    this.emailInfoFormGroup.controls.confirmEmail.updateValueAndValidity();

    if (this.accountInfoForm.valid) {
      this.accountInfoSub = this.accountRegService
        .postValidateIndirectAccountInfo(this.getUserDataForRequest())
        .subscribe(
          (res) => {
            if (res === null) {
              this.errorMsg = false;
              this.isIndirectB2BUnitValidated = true;
              this.cdr.detectChanges();
            }
          },
          (error) => {
            if (!!error) {
              this.errorMsg = true;
              this.checkIndirectErrors(error);
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              if (this.errorMsg) {
                window.scroll(0, 0);
                this.globalMessage.add(
                  this.errorMessageText,
                  GlobalMessageType.MSG_TYPE_ERROR
                );
              }
              this.cdr.detectChanges();
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }

  }

  validateIndirectAccountInAkamai() {
    this.accountInfoForm.markAllAsTouched();
    if (this.accountInfoForm.get('registeredAkamaiUser').value || this.skipAkamaiValidation) {
      this.accountInfoForm.controls.emailInfo.clearValidators();
      this.emailInfoFormGroup.controls.email.setValidators([Validators.required, Validators.pattern(emailRegexExp)]);
      this.emailInfoFormGroup.controls.confirmEmail.clearValidators();
    } else {
      this.accountInfoForm.controls.emailInfo.setValidators([Validators.required, this.emailMatchValidator]);
      this.emailInfoFormGroup.controls.email.setValidators([Validators.required, Validators.pattern(emailRegexExp)]);
      this.emailInfoFormGroup.controls.confirmEmail.setValidators([Validators.required, Validators.pattern(emailRegexExp)]);
      this.accountInfoForm.controls.userInfoDetails.setValidators([this.pwdMatchValidator]);
      this.userInfoDetailsGroup.controls.password.setValidators([Validators.required, this.validatePassword.bind(this)]);
      this.userInfoDetailsGroup.controls.confirmPassword.setValidators([Validators.required, this.validatePassword.bind(this)]);
    }

    this.accountInfoForm.controls.emailInfo.updateValueAndValidity();
    this.emailInfoFormGroup.controls.email.updateValueAndValidity();
    this.emailInfoFormGroup.controls.confirmEmail.updateValueAndValidity();
    this.accountInfoForm.controls.userInfoDetails.updateValueAndValidity();
    this.userInfoDetailsGroup.controls.password.updateValueAndValidity();
    this.userInfoDetailsGroup.controls.confirmPassword.updateValueAndValidity();

    if (this.accountInfoForm.valid) {
      this.accountInfoSub = this.accountRegService
        .postValidateIndirectAccountInAkamai(this.getUserDataForRequest())
        .subscribe(
          (res) => {
            if (res === null) {
              this.errorMsg = false;
              this.accountInfoForm.get('userInfoDetails.confirmPassword').setValue(this.accountInfoForm.get('userInfoDetails.password').value);
              this.accountInfoForm.get('emailInfo.confirmEmail').setValue(this.accountInfoForm.get(this.EMAIL_KEY).value);
              const accountInfo = {
                header: 'userInformation',
                form: this.accountInfoForm,
              };
              this.accountRegService.sectionChange.next(accountInfo);
            }
          },
          (error) => {
            if (!!error) {
              this.errorMsg = true;
              this.checkIndirectErrorsFromAkamai(error);
              window.scroll(0, 0);
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              this.globalMessage.add(
                this.errorMessageText,
                GlobalMessageType.MSG_TYPE_ERROR
              );
              this.cdr.detectChanges();
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }

  }

  setUserDetails(formDta, userDetails): any {
    for (const key in formDta) {
      if (
        key !== 'userInfoDetails' &&
        key !== 'emailInfo'
      ) {
        userDetails[key] = formDta[key];
      }
    }
    return userDetails;
  }

  getUserDataForRequest(): any {
    let userDetails = {};
    const formDta = { ...this.accountInfoForm.value };
    if (!!formDta) {
      userDetails = this.setUserDetails(formDta, userDetails);
    }
    const userData = {
      ...userDetails,
      ...this.accountInfoForm.get('userInfoDetails').value,
      ...this.accountInfoForm.get('emailInfo').value
    };
    return userData;
  }


  validateDirectAccountInfo() {
    this.accountInfoForm.markAllAsTouched();

    this.emailInfoFormGroup.controls.email.clearValidators();
    this.emailInfoFormGroup.controls.confirmEmail.clearValidators();
    this.accountInfoForm.controls.emailInfo.clearValidators();
    this.accountInfoForm.controls.emailInfo.updateValueAndValidity();
    this.emailInfoFormGroup.controls.email.updateValueAndValidity();
    this.emailInfoFormGroup.controls.confirmEmail.updateValueAndValidity();

    if (this.accountInfoForm.valid) {
      this.accountInfoSub = this.accountRegService
        .postValidateAccountInfo(this.accountInfoForm.value)
        .subscribe(
          (res) => {
            if (res === null) {
              this.errorMsg = false;
              delete this.accountInfoForm.value?.recaptchaReactive;
              const accountInfo = {
                header: 'userInformation',
                form: this.accountInfoForm,
              };
              this.accountRegService.sectionChange.next(accountInfo);
            }
          },
          (error) => {
            if (!!error) {
              this.errorMsg = true;
              this.checkErrors(error);
              window.scroll(0, 0);
              this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
              this.globalMessage.add(
                this.errorMessageText,
                GlobalMessageType.MSG_TYPE_ERROR
              );
              this.cdr.detectChanges();
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  checkErrors(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      this.billToShipError =
        error?.error?.errors[0].message === this.errorTypes[0] ? true : false;
      this.accountTypeError =
        error?.error?.errors[0].message === this.errorTypes[5] ? true : false;
      if (this.accountTypeError){
        this.errorMsg = false;
      }

    }
  }

  checkIndirectErrors(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      this.billToShipError =
        error?.error?.errors[0].message === this.errorTypes[0] ? true : false;
      this.isEmailExists =
        error?.error?.errors[0].message === this.errorTypes[1] ? true : false;
      this.isPasswordEmptyInAkamai =
        error?.error?.errors[0].message === this.errorTypes[4] ? true : false;
      this.accountInfoForm.get('registeredAkamaiUser').setValue(
        error?.error?.errors[0].message === this.errorTypes[2] ? true : false);

      if (!this.billToShipError && !this.isEmailExists && this.accountInfoForm.get('registeredAkamaiUser').value && !this.isPasswordEmptyInAkamai) {
        this.isIndirectB2BUnitValidated = true;
        this.errorMsg = false;
        this.infoMsg = true;
      } else if (this.isPasswordEmptyInAkamai) {
        this.isIndirectB2BUnitValidated = false;
        this.errorMsg = false;
      } else {
        this.isIndirectB2BUnitValidated = false;
      }
    }
  }

  checkIndirectErrorsFromAkamai(error) {
    if (!!error?.error?.errors && error?.error?.errors.length > 0) {
      this.akamaiPasswordValidationError = error?.error?.errors[0].message === this.errorTypes[3] ? true : false;
    }
  }

  radioButtonIsWholesalerFromAkamaiIfExist() {
    this.accountInfoForm.get('wholesalerFlag').setValue(!!this.userAuthDetails);
    if (this.accountInfoForm.get('wholesalerFlag')?.value) {
      this.accountRegService
        .getJanrainUserDataByUserName(this.userAuthDetails?.userId)
        .subscribe((res) => {
          if (res) {
            this.janrainUserData = JSON.parse(JSON.stringify(res));
            if (!!this.janrainUserData && !!this.janrainUserData?.userName)
            {
              this.accountInfoForm.get(this.EMAIL_KEY).setValue(this.janrainUserData?.email);
              this.skipAkamaiValidation = true;
              this.accountInfoForm.get('registeredAkamaiUser').setValue(true);
            }
            this.cdr.detectChanges();
          }
        });
    }
    this.userInfoDetailsGroup.get('password').setValue(this.userAuthDetails?.password);
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  sendResetPasswordEmail() {
    const email = this.accountInfoForm.get(this.EMAIL_KEY).value;
    if (!!email) {
      this.accountRegService.postSendResetPasswordEmail(email).subscribe(
        (res) => {
          this.resetPasswordEmailSent = true;
          this.cdr.detectChanges();
        },
        (error) => {
          this.resetPasswordEmailSent = false;
          this.cdr.detectChanges();
        });
    }
  }

  ngOnDestroy() {
    this.auth.userAuthDetails = undefined;
    this.accountInfoSub?.unsubscribe();
    this.taxAndAccountNumberValidatorSub?.unsubscribe();
  }
}
