import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OccEndpointsService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { CustomAddToCartService } from 'src/app/custom-add-to-cart/custom-add-to-cart.service';
import * as config from '../../config/app.config';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @Output() dataOutput: EventEmitter<File> = new EventEmitter();
  selectedFile: any;
  userId: any;
  userSub: Subscription;
  postSub: Subscription;
  cartItemsSub: Subscription;
  cartId: any;

  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly _user: UserAccountFacade,
    private _occEndpoints: OccEndpointsService,
    private _http: HttpClient,
    private addToCartService: CustomAddToCartService
  ) {}

  ngOnInit(): void {
    this.userSub = this._user.get().subscribe((res) => {
      this.userId = res?.uid;
    });
    this.cartItemsSub = this.addToCartService.cartItems$.subscribe(
      (cartData: any) => {
        if (cartData) {
          this.cartId = cartData.code;
        }
      }
    );
  }

  onFileChanged(event): void {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
      this.myForm.patchValue({
        fileSource: this.selectedFile,
      });
      this.onSubmit(this.selectedFile);
    }
  }

  onSubmit(file): void {
    const formData = new FormData();
    let params = new HttpParams();
    params = params.append('cartId', this.cartId);
    formData.append('file', this.myForm.get('fileSource').value);
    this.postSub = this._http
      .post(
        this._occEndpoints.getBaseUrl() +
          '/users/' +
          this.userId +
          config.DOCUMENT_UPLOAD,
        formData,
        {
          params: params,
        }
      )
      .subscribe(
        (res) => {
          if (res == 'S') {
            this.dataOutput.emit(file);
          }
        },
      );
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe();
    this.postSub?.unsubscribe();
    this.cartItemsSub.unsubscribe();
  }
}
