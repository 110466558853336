import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ProductReferenceService,
  ProductService,
  RoutingService
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../../shared/services/data-layer.service';
import { ProductUtils } from '../../shared/utils/productUtils';
import { CustomPdpService } from '../custom-pdp.service';
@Component({
  selector: 'app-custom-pdp',
  templateUrl: './custom-pdp.component.html',
  styleUrls: ['./custom-pdp.component.scss'],
})
export class CustomPdpComponent implements OnInit, OnDestroy {
  sessionStorage = sessionStorage;
  productCode: string;
  productDetails: any;
  monographLink: any;
  monograph: any;
  iscarousel = false;
  buttonName = 'Login to order';
  panelVolumeExpanded = false;
  panelProductExpanded = false;
  popoverTimer: any;
  userDetails: any;
  relatedProducts = [];
  relatedProductsFormatted: any;

  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  description: any;
  cartId: any;
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: true,
    arrows: true,
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 1100, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };
  displaySection = false;
  itemAddedQuantity: any;
  cartItems: any;
  cartDetailsSub: Subscription;
  userSub: Subscription;
  cartItemsSub: Subscription;
  productCodeSub: Subscription;
  createdCartSub: Subscription;
  relatedProdSub: Subscription;
  viewitemRan: boolean = false;
  BUTTON_NAMES = {
    RESERVE_NOW: 'Reserve now',
    ADD_TO_CART: 'Add to cart'
  }
  
  constructor(
    private readonly productSearch: ProductService,
    private readonly route: ActivatedRoute,
    private readonly routerservice: RoutingService,
    private readonly user: UserAccountFacade,
    private readonly pdpService: CustomPdpService,
    private readonly referenceService: ProductReferenceService,
    private readonly cdr: ChangeDetectorRef,
    private readonly routingService: RoutingService,
    private readonly modalService: NgbModal,
    private readonly sanitizer: DomSanitizer,
    private dataLayerService: DataLayerService
  ) { }

  ngOnInit(): void {
    this.route.params.forEach((params: Params) => {
      this.populatePDP();
    });
  }
  populatePDP() {
    this.viewitemRan = false;
    this.productDetails = [];
    this.productCode = this.route.snapshot.params.productCode;
    this.pdpService.getPDPCategory(this.productCode);
    this.userSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (this.userDetails && this.userDetails !== null) {
        this.pdpService.getCarts(this.userDetails?.userName);
      }
    });
    this.cartItemsSub = this.pdpService.cartItems$.subscribe((res) => {
      if (res) {
        this.cartItems = res?.carts;
        this.cartItems = this.cartItems.filter((item) => item?.saveTime === undefined);
      }
    });
    this.productCodeSub = this.pdpService.productCode$.subscribe((res) => {
      if (res) {
        if (!this.viewitemRan) {
          this.viewitemRan = true;
          this.dataLayerService.viewItemDataLayer(res);
        }
        this.displaySection = res.hasOwnProperty('code');
        this.productDetails = res;
        this.description = this.sanitizer.bypassSecurityTrustHtml(this.productDetails.description);
        this.monograph = this.productDetails?.productDocuments;
        if (this.monograph) {
          for (const link of this.monograph?.productDocument) {
            this.monographLink = this.sanitizer.bypassSecurityTrustResourceUrl(link.url);
          }
        }
        if (this.userDetails !== undefined) {
          if (this.productDetails.flu === true) {
            this.buttonName = this.BUTTON_NAMES.RESERVE_NOW;
          } else {
            this.buttonName = this.BUTTON_NAMES.ADD_TO_CART;
          }
        } else {
          this.buttonName = 'Login to order';
        }
      }
      this.cdr.detectChanges();
    });
  }
  addToCart(productCode, popover): void {
    if (this.buttonName === this.BUTTON_NAMES.RESERVE_NOW) {
      this.navigateToFlu();
    } else if (this.buttonName === this.BUTTON_NAMES.ADD_TO_CART) {
      const inputElement = document.getElementById(
        `prodQuantity${productCode}`
      ) as HTMLInputElement;
      const params = {
        product: {
          code: productCode,
        },
        quantity: inputElement.value,
      };
      this.itemAddedQuantity = inputElement.value;
      if (this.cartItems && this.cartItems?.length === 0) {
        this.pdpService.createCart(this.userDetails?.userName);
        this.createdCartSub = this.pdpService.createdCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.pdpService.getCarts(this.userDetails?.userName);
            this.UpdateCartDetails(params, popover);
          }
        });
      } else {
        if (this.cartId === undefined) {
          this.cartItems = this.cartItems && this.cartItems?.length > 0 ? this.cartItems[this.cartItems?.length - 1] : [];
          this.cartId = this.cartItems?.code;
        }
        this.UpdateCartDetails(params, popover);
      }
    }
  }
  addToCartWHSProduct(productCode, whsCode, popover): void {
    if (this.buttonName === this.BUTTON_NAMES.RESERVE_NOW) {
      this.navigateToFlu();
    } else if (this.buttonName === this.BUTTON_NAMES.ADD_TO_CART) {
      const inputElement = document.getElementById(
        `prodQuantity${whsCode}`
      ) as HTMLInputElement;
      const params = {
        product: {
          code: productCode,
        },
        quantity: inputElement.value,
        selectedB2BUnit: whsCode,
      };
      this.itemAddedQuantity = inputElement.value;
      if (this.cartItems && this.cartItems?.length === 0) {
        this.pdpService.createCart(this.userDetails?.userName);
        this.createdCartSub = this.pdpService.createdCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.pdpService.getCarts(this.userDetails?.userName);
            this.UpdateCartDetails(params, popover);
          }
        });
      } else {
        if (this.cartId === undefined) {
          this.cartItems = this.cartItems && this.cartItems?.length > 0 ? this.cartItems[this.cartItems?.length - 1] : [];
          this.cartId = this.cartItems?.code;
        }
        this.UpdateCartDetails(params, popover);
      }
    }
  }
  UpdateCartDetails(params, popover): void {
    let popoverShown = false;
    this.pdpService.updateCart(params, this.userDetails?.userName, this.cartId, null);
    this.cartDetailsSub = this.pdpService.cartDetailsUpdate$.subscribe((res) => {
      if (res) {
        if (!popoverShown) {
          clearTimeout(this.popoverTimer);
          popover.toggle();
          this.popoverTimer = setTimeout(() => popover.close(), 2500);
        }
        popoverShown = true;
      }
    });
  }
  performQuantityComputation(operator, idData, quantityOfUnits): void {
    ProductUtils.alterQuantity(operator, idData, quantityOfUnits);
  }

  goToLogin() {
    this.routingService.goByUrl('/login');
  }
  openImage(content) {
    this.modalService.open(content,
      {
        windowClass: 'carousel-modal',
      }
    );
  }
  navigateToFlu() {
    this.routerservice.goByUrl('/influenza/dashboard');
  }
  ngOnDestroy() {
    this.pdpService.productCode.next(null);
    this.displaySection = false;
    this.cartDetailsSub?.unsubscribe();
    this.userSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.productCodeSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
    this.relatedProdSub?.unsubscribe();
  }
}
