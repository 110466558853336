import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public emailConsent = new Subject<any>();
  emailConsent$ = this.emailConsent.asObservable();
  public snippetData = new Subject<any>();
  snippetData$ = this.snippetData.asObservable();
  constructor(private readonly baseService: BaseService) {
  }

  setSessionStorage(keyName: string, data: any): any {
    sessionStorage.setItem(keyName, JSON.stringify(data));
  }
  getSessionStorage(keyName): any {
    const value = JSON.parse(sessionStorage.getItem(keyName));
    return value;
  }
  // Using Local storage for ASM
  setLocalStorage(keyName: string, data: any): any {
    localStorage.setItem(keyName, JSON.stringify(data));
  }
  getLocalStorage(keyName): any {
    const value = JSON.parse(localStorage.getItem(keyName));
    return value;
  }
  //End
  getEmailConsent(userId) {
    this.baseService.get('/users/' + userId + '/emailconsent?fields=DEFAULT').subscribe((res) => {
      if (res) {
        this.emailConsent.next(res);
      }
    });
  }
  setEmailConsent(userId, emailConsent) {
    this.baseService.post('/users/' + userId + '/emailconsent?' + 'emailConsent=' + emailConsent, {}).subscribe();
  }
  getsnippetAPI() {
    this.baseService.get('/fetchConfig/global').subscribe((res) => {
      if (res) {
        this.snippetData.next(res);
      }
    });
  }
}