import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataLayerService } from '../../shared/services/data-layer.service';
import { CustomRegistrationService } from '../custom-registration.service';
import {
  globalErrorMsgEng,
  globalErrorMsgFr,
  siteLanguagesEng,
} from '../registration.constants';
const emailRegexExp =
  '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {
  emailAlreadyVerifiedError = false;
  emailNotRegisteredError = false;
  verifyEmailSent = false;
  emailVerificationForm: FormGroup;
  errorMessageText: string = globalErrorMsgEng;
  verificationCode: any;
  verifyEmailSub: Subscription;
  sendVerificationEmailSub: Subscription;
  verifyEmailError = false;
  errorMessages: string[] = [
    'email.address.already.verified',
    'email.address.not.registered',
  ];

  constructor(
    private readonly globalMessage: GlobalMessageService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly accountRegService: CustomRegistrationService,
    private readonly routingService: RoutingService,
    private readonly ref: ChangeDetectorRef,
    private readonly lang: LanguageService,
    private readonly translateService: TranslationService,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.lang.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.verificationCode = params.verification_code;
    });
    if (!!this.verificationCode) {
      this.verifyEmailSub = this.accountRegService
        .postVerifyEmail(this.verificationCode)
        .subscribe(
          (res) => {
            if (res === null) {
              this.dataLayerService.pharma_registrationConfirmationDataLayer();
              this.successRedirectToLogin();
            }
          },
          (error) => {
            if (!!error) {
              this.verifyEmailError = true;
              this.ref.detectChanges();
            }
          }
        );
    } else {
      this.verifyEmailError = true;
    }
    this.emailVerificationForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(emailRegexExp),
      ]),
    });
  }

  sendVerificationEmail() {
    this.verifyEmailSent = false;
    this.emailAlreadyVerifiedError = false;
    this.emailNotRegisteredError = false;
    this.emailVerificationForm.markAllAsTouched();
    if (this.emailVerificationForm.valid) {
      this.sendVerificationEmailSub = this.accountRegService
        .postSendVerificationEmail(
          this.emailVerificationForm.get('email').value
        )
        .subscribe(
          (res) => {
            if (res === null) {
              this.verifyEmailSent = true;
              this.ref.detectChanges();
            }
          },
          (error) => {
            if (!!error?.error?.errors && error?.error?.errors.length > 0) {
              for (const errorItem of error?.error?.errors) {
                if (errorItem?.message === this.errorMessages[0]) {
                  this.emailAlreadyVerifiedError = true;
                  window.scroll(0, 0);
                  this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
                  this.verifyEmailError = true;
                  this.ref.detectChanges();
                }
                if (errorItem?.message === this.errorMessages[1]) {
                  this.emailNotRegisteredError = true;
                  window.scroll(0, 0);
                  this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
                  this.verifyEmailError = true;
                  this.ref.detectChanges();
                }
              }
            }
          }
        );
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  redirectToLogin() {
    this.routingService.goByUrl('/login');
  }

  private successRedirectToLogin() {
    this.translateService
      .translate('account.successFullyVerified')
      .pipe(take(1))
      .subscribe({
        next: (text: string) => {
          this.globalMessage.add(text, GlobalMessageType.MSG_TYPE_CONFIRMATION);
          this.routingService.goByUrl('/login');
        },
      });
  }

  ngOnDestroy() {
    this.verifyEmailSub?.unsubscribe();
    this.sendVerificationEmailSub?.unsubscribe();
  }
}
