import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  CmsService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CustomProdPgService } from 'src/app/custom-prod-pg/custom-prod-pg.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import * as _ from 'underscore';
import * as config from "../../../shared/config/app.config";
import { BaseService } from '../../../shared/services/base.service';
import { DataLayerService } from '../../../shared/services/data-layer.service';
import { CustomAddToCartService } from '../../custom-add-to-cart.service';
import { ProductDetailsComponent } from '../product-details/product-details.component';
@Component({
  selector: 'app-standard-cart',
  templateUrl: './standard-cart.component.html',
  styleUrls: ['./standard-cart.component.scss'],
})
export class StandardCartComponent implements OnInit, OnDestroy {
  sessionStorage = sessionStorage;
  deliveryPickup: string = 'pickup';
  isSticky: boolean = false;
  popoverPosition: 'top' | 'top-left';
  public productInfo: {
    available: boolean;
    availabilityText: string;
    batchInfo: {
      batchNumber: string;
      expiryDate: string;
      quantity: number;
    };
    images: any;
    code: string;
    description: string;
    discontinued: boolean;
    sapAllocation: boolean;
    displayProduct: boolean;
    flu: boolean;
    nameHtml: any;
    summary: any;
    productUpdate: string;
    productDocuments: {
      productDocument: [
        {
          url: string;
        }
      ];
    };
    name: string;
    orderable: boolean;
    quantityPerUnit: number;
    productUpdates: string;
    productDocument: any;
    volumePrices: [
      {
        currencyIso: string;
        formattedValue: string;
        maxQuantity: string;
        minQuantity: string;
        priceType: string;
        value: string;
      }
    ];
    url: string;
    stock: {
      isValueRounded: boolean;
    };
    isValueRounded: boolean;
    yellowFever: boolean;
    volumePricesFlag: boolean;
  } = null;
  standardCartDetails: any;
  productEntries: any = [];
  public images: any;
  updateTotal: boolean;
  updateEntriesData: any = [];
  subTotal: number;
  baseUrl: string;
  minDeliveryDate = new Date();
  maxDeliveryDate = new Date();
  minPickupDate = new Date();
  maxPickupDate = new Date();
  lastDayofMonthDelivery = new Date();
  lastDayofMonthPickup = new Date();
  currentTime = new Date().getHours();
  pickupTimeFormat: any = [];
  timeFormat: any;
  selectedTime: any;
  deliveryDate: Date;
  deliveryCrossIcon: boolean;
  pickupDate: Date;
  deliveryDateFormat: string;
  pickupDateFormat: string;
  pickupCrossIcon: boolean;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  closeResult = '';
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  listSelectedData: any;
  userAccountgetData: any;
  saveCartName: string;
  popoverConfig: NgbPopoverConfig;
  cartId: any;
  errorMsg: string;
  deleteSuccess: string;
  addSuccess = false;
  cartItems: any;
  changeLocation = false;
  templatePage: any;
  prodAddedSub: Subscription;
  cartItemsSub: Subscription;
  removeEntriesSub: Subscription;
  deleteCartSub: Subscription;
  cartDetailsSub: Subscription;
  checkoutDetailsSub: Subscription;
  checkoutFailedSub: Subscription;
  userAccountDetailsSub: Subscription;
  changedLocSub: Subscription;
  saveCartInvalidSub: Subscription;
  saveCartSuccessSub: Subscription;
  plpMiniCartSub: Subscription;
  userDataSub: Subscription;
  @ViewChild('p2') public savePopover: NgbPopover;
  orderBlock = false;
  showDangerAlert = false;
  checkoutClicked = false;
  yfEntry = false;
  cartContainsWholesalerProducts = false;
  cartContainsDirectProducts = false;
  showDisclaimer = false;
  showDirectProductsDisclaimer = false;
  productNames: Array<string> = [];
  cartDetailsRan: boolean = false;
  delcartDetailsRan: boolean = false;
  isAsmEnabled: any;
  csAgentAuthSubscription: Subscription;
  PROCESS_MODE = 'standard process'
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly addToCart: CustomAddToCartService,
    readonly user: UserAccountFacade,
    private readonly modalService: NgbModal,
    private localeService: BsLocaleService,
    public datepipe: DatePipe,
    private readonly config: NgbPopoverConfig,
    private readonly route: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly plpService: CustomProdPgService,
    private globalMessage: GlobalMessageService,
    private readonly pageDate: CmsService,
    private readonly translation: TranslationService,
    private readonly baseService:BaseService,
    private readonly csAgentAuthService: CsAgentAuthService,
    private dataLayerService: DataLayerService
  ) {
    this.images = {};
    this.updateTotal = false;
    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    enGbLocale.week.dow = 0;
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
    this.pickupTimeFormat = [
      '09:00 am',
      '10:00 am',
      '11:00 am',
      '12:00 pm',
      '01:00 pm',
      '02:00 pm',
      '03:00 pm',
      '04:00 pm',
      '05:00 pm',
    ];
    this.selectedTime = null;
    config.autoClose = 'outside';
    this.deleteSuccess = '';
    this.addSuccess = false;
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 400;
  }
  ngOnInit(): void {
    this.getUserAccountDataFromDb(localStorage.getItem('user_id'));
    this.csAgentAuthSubscription = this.csAgentAuthService.isCustomerEmulated().subscribe((res) => {
      this.isAsmEnabled = res ? res : false;
    });
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    if (this.addToCart?.restoredInformation.length > 0) {
      this.showRestoreCartError();
    }
    this.plpMiniCartSub = this.plpService.getUpdateMiniCart().subscribe((res) => {
      if (res) {
        this.addToCart.removedEntries(res, this.userAccountgetData?.userName);
        this.removeEntriesMapping();
      }
    });
    this.plpService.stdCartMsg.next(false);
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.pageDate.getCurrentPage().subscribe((res) => {
      if (res) {
        this.templatePage = res?.template;
      }
    });
    this.userDataSub = this.user.get().subscribe((res) => {
      if (res && res != null) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData.userName);
        this.productEntries = [];
        this.getCartDetails();
        this.cdr.detectChanges();
      }
    });
    this.prodAddedSub = this.plpService.stdCartMsg$.subscribe((res) => {
      if (res) {
        this.addSuccess = true;
        this.deleteSuccess = '';
        this.plpService.stdCartMsg.next(false);
      }
    });
    this.setDeliveryDate();
    this.setPickupDate();
    this.popoverPosition = window.innerWidth < 768 ? 'top-left' : 'top';
  }

  showRestoreCartError() {
    if (this.addToCart?.restoredInformation[0]?.entry?.wholesalerProduct) {
      this.addToCart.restoredInformation.forEach(modification => {
        this.translation.translate('addToCartItems.restoreCartErrorProductInfo', {
          productName: modification?.entry?.product?.name,
          wholesalerName: modification?.entry?.wholesalerDisplayName
        }).subscribe((resp) => {
          this.productNames.push(resp);
        });
      });
      this.translation.translate('addToCartItems.restoreCartError', {
        productNames: this.productNames.join(', '),
      }).subscribe((resp) => {
        this.globalMessage.add(resp,
          GlobalMessageType.MSG_TYPE_ERROR, 5000
        );
      });
    }
    else {
      this.addToCart.restoredInformation.forEach(modification => {
        this.productNames.push(modification?.entry?.product?.name);
      });
      this.translation.translate('addToCartItems.restoreDirectCartError', {
        productNames: this.productNames
      }).subscribe((resp) => {
        this.globalMessage.add(resp,
          GlobalMessageType.MSG_TYPE_ERROR, 5000
        );
      });
    }
    this.addToCart.restoredInformation = [];
  }

  getCartDetails() {
    this.addToCart.deleteCartCache();
    this.addToCart.getCarts(this.cartId, this.userAccountgetData?.userName);
    this.cartItemsSub = this.addToCart.cartItems$.subscribe((cartData: any) => {
      if (cartData) {
        this.cartDataMapping(cartData);
      }
    });
    if (this.cartId && this.templatePage === 'CartPageTemplate') {
      this.addToCart.removedEntries(this.cartId, this.userAccountgetData?.userName);
      this.removeEntriesMapping();
    }
  }
  removeEntriesMapping() {
    this.removeEntriesSub = this.addToCart.removedItems$.subscribe((res: any) => {
      if (res) {
        const removedData = res?.removedEntries?.cartModifications;
        let stdCartDetails;
        this.yfEntry = false;
        stdCartDetails = res?.childCarts;
        if (stdCartDetails) {
          stdCartDetails = stdCartDetails.filter(
            (item) => item.cartType === 'STANDARD'
          );
          if (stdCartDetails && stdCartDetails?.length > 0) {
            stdCartDetails = Object.assign({}, ...stdCartDetails);
            for (const entries of stdCartDetails?.entries) {
              if (entries?.product?.yellowFever) {
                this.yfEntry = true;
              }
            }
          }
        }
        if (!stdCartDetails?.shipToParty?.yellowFeverFlag && this.yfEntry) {
          this.yfEntry = true;
        } else {
          this.yfEntry = false;
          this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
        }
        if (removedData && removedData?.length > 0) {
          for (const data of removedData) {
            if (!stdCartDetails?.shipToParty?.yellowFeverFlag && this.yfEntry) {
              this.globalMessage.add(data.statusMessage,
                GlobalMessageType.MSG_TYPE_ERROR, 5000000
              );
            } else {
              this.globalMessage.add(data.statusMessage,
                GlobalMessageType.MSG_TYPE_ERROR
              );
            }
          }
        }
        this.addToCart.getAllCarts(this.userAccountgetData?.userName);
        this.addToCart.removedItems.next(false);
      }
    });
  }

  public getUserAccountDataFromDb(userId): void {
    this.baseService
      .get(`${config.USER_ACCOUNT}${userId}/b2bUnits/`, { fields: 'FULL' })
      .subscribe((res) => {
        if (res!==null && res!==undefined && res?.length!==0) {
          localStorage.setItem(
            'user_account_id',
            res?.defaultB2BUnit?.uid
          );
        }
      });
  }

  public getUserAccountDataFromDbupdate(userId, data): void {
    this.baseService
      .get(`${config.USER_ACCOUNT}/current/b2bUnits/`, { fields: 'FULL' })
      .subscribe((res) => {
        if (res!==null && res!==undefined && res?.length!==0) {
          localStorage.setItem(
            'user_account_id',
            res?.defaultB2BUnit?.uid
          );
          this.dataLayerService.viewCartDataLayer(
            data,
            this.PROCESS_MODE
          );
        }
      });
  }
  
  cartDataMapping(data): void {
    let stdCartDetails = [];
    this.productEntries = [];
    this.cartContainsWholesalerProducts = false;
    this.cartContainsDirectProducts = false;
    this.showDisclaimer = false;
    this.showDirectProductsDisclaimer = false;
    this.cartId = data?.code;
    stdCartDetails = data?.childCarts;
    if (stdCartDetails) {
      stdCartDetails = stdCartDetails.filter(
        (item) => item.cartType === 'STANDARD'
      );
      if (!this.cartDetailsRan) {
        this.cartDetailsRan = true;
        if(this.isAsmEnabled){
          this.dataLayerService.viewCartDataLayer(
            data,
            this.PROCESS_MODE
          );
        }else{
            this.getUserAccountDataFromDbupdate(localStorage.getItem('user_id'), data);
        }
      }
      
      this.standardCartDetails = Object.assign({}, ...stdCartDetails);
      for (const cartDetails of stdCartDetails) {
        for (let entries of cartDetails.entries) {
          const imagesFormat = _.clone(
            _.findWhere(entries.product?.images, { format: 'thumbnail' })
          );
          if (imagesFormat) {
            imagesFormat.url = this.baseUrl + imagesFormat?.url;
          }
          this.images = { PRIMARY: imagesFormat };
          entries = { ...entries, ...this.images };
          if (!this.cartContainsWholesalerProducts && !!entries?.wholesalerProduct) {
            this.cartContainsWholesalerProducts = true;
            this.showDisclaimer = true;
          } else if (!this.cartContainsDirectProducts && !entries?.wholesalerProduct) {
            this.cartContainsDirectProducts = true;
          }
          this.productEntries.push(entries);
        }
      }
      if (this.cartContainsDirectProducts && this.cartContainsWholesalerProducts) {
        this.showDirectProductsDisclaimer = true;
      }
      if (this.standardCartDetails?.shipToParty) {
        if (this.selectedData === undefined) {
          this.selectedData = this.standardCartDetails?.shipToParty?.shippingAddresses.filter((item) => item.unitID === this.standardCartDetails.shipToParty.uid);
          this.selectedData = _.first(this.selectedData);
        }
      } else {
        if (this.getuserData && this.getuserData?.length > 0) {
          this.selectedData = _.first(this.getuserData.filter((item) => item.unitID === this.userAccountgetData?.uid));
        }
      }

      this.cdr.detectChanges();
    }
  }
  performQuantityComputation(
    operator,
    idData,
    quantityOfUnits,
    entryNumber
  ): void {
    ProductUtils.alterQuantity(operator, entryNumber, quantityOfUnits);
    this.updateTotal = true;
    const inputElement = document.getElementById(
      `prodQuantity${entryNumber}`
    ) as HTMLInputElement;
    const params = { entryNumber: entryNumber, quantity: inputElement.value };
    if (
      this.updateEntriesData.some(
        ({ entryNumber }) => entryNumber === params.entryNumber
      )
    ) {
      this.updateEntriesData[
        this.updateEntriesData.findIndex(
          (el) => el.entryNumber === params.entryNumber
        )
      ] = params;
    } else {
      this.updateEntriesData = [...this.updateEntriesData, params];
    }
  }
  deleteCart(entryNumber, prodName): void {
    this.delcartDetailsRan = false;
    this.addToCart.deleteCartItem(entryNumber, this.standardCartDetails.code, this.cartId);
    this.deleteCartSub = this.addToCart.deleteItems$.subscribe((res) => {
      if (res) {
        this.addToCart.deleteItems.next(false);
        this.addToCart.removedEntries(this.cartId, this.userAccountgetData?.userName);
        this.removeEntriesMapping();
        this.deleteSuccess = prodName;
        this.addSuccess = false;
        /************************ Delete Product View cart DL Push ***************************/
        this.addToCart.getAllCarts(this.userAccountgetData?.userName);
        this.addToCart.getCarts(this.cartId, this.userAccountgetData?.userName);
        this.cartItemsSub = this.addToCart.cartItems$.pipe(filter(prods => !!prods), take(2)).subscribe((cartData: any) => {
            if (!this.delcartDetailsRan) {
              this.delcartDetailsRan = true;
              this.dataLayerService.viewCartDataLayer(
                cartData,
                this.PROCESS_MODE
              )
            }
        });
        /************************ Delete Product View cart DL Push End ***************************/
      }
    });
  }
  closeAlert() {
    this.deleteSuccess = '';
    this.addSuccess = false;
  }
  closeDangerAlert() {
    this.showDangerAlert = false;
  }
  clearChildCart(): void {
    this.addToCart.clearCart(this.standardCartDetails.code);
  }
  openPopover(popover) {
    popover.open();
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg = "";
  }
  updateCart(): void {
    this.addToCart.updateCartDetails(this.standardCartDetails.code, this.updateEntriesData, this.cartId);
    this.cartDetailsSub = this.addToCart.cartDetails$.subscribe((res: any) => {
      if (res) {
        this.updateTotal = false;
        let stdCartDetails = [];
        stdCartDetails = res?.childCarts;
        stdCartDetails = stdCartDetails.filter(
          (item) => item.cartType === 'STANDARD'
        );
        this.dataLayerService.addUpdateCartDataLayer(
          stdCartDetails,
          this.PROCESS_MODE,
          null,
          'cart'
        );
        this.standardCartDetails = Object.assign({}, ...stdCartDetails);
        this.dataLayerService.viewCartUpdateQuantityDataLayer(
          this.standardCartDetails,
          this.PROCESS_MODE
        );
        this.standardCartDetails = Object.assign({}, ...stdCartDetails);
      }
      this.cdr.detectChanges();
    });
  }
  productDetails(productDetails): void {
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = this.standardCartDetails.code;
    modalRef.componentInstance.unitSection = 'Update';
  }

  onDeliveryDateChange(value: Date): void {
    if (value) {
      this.deliveryCrossIcon = true;
      this.deliveryDateFormat = this.datepipe.transform(value, 'yyyy-MM-dd');
    } else {
      this.deliveryCrossIcon = false;
    }
  }
  onPickupDateChange(value: Date): void {
    if (value) {
      this.pickupCrossIcon = true;
      this.pickupDateFormat = this.datepipe.transform(value, 'yyyy-MM-dd');
      if (value.getDate() !== this.minPickupDate.getDate()) {
        this.timeFormat = this.pickupTimeFormat;
        this.selectedTime = _.first(this.timeFormat);
      } else {
        this.setPickupTime();
      }
    } else {
      this.pickupCrossIcon = false;
    }
  }
  clearDate(dpDate): void {
    if (dpDate === 'Delivery') {
      this.deliveryDate = null;
      this.deliveryCrossIcon = false;
    } else {
      this.pickupDate = null;
      this.pickupCrossIcon = false;
      this.selectedTime = null;
    }
  }
  proceedToCheckout(): void {
    if (this.deliveryDateFormat || (this.pickupDateFormat && this.selectedTime) || (this.cartContainsWholesalerProducts && !this.cartContainsDirectProducts)) {
      const params = {
        customerId: this.usergetDataObj.uid,
        date:
          this.deliveryPickup === 'delivery' &&
            (this.deliveryDateFormat || this.pickupDateFormat)
            ? this.deliveryDateFormat
            : this.pickupDateFormat,
        deliveryMode:
          this.deliveryPickup === 'delivery' ? 'standard' : 'pickup',
        pickupTime:
          this.deliveryPickup === 'pickup' && this.selectedTime
            ? this.selectedTime
            : '',
        shipToUnitId: this.selectedData?.unitID,
        standard: true,
      };
      this.addToCart.checkoutCart(params, this.cartId);
      this.checkoutClicked = true;
      this.checkoutDetailsSub = this.addToCart.checkoutDetails$.subscribe((res) => {
        if (res) {
          this.checkoutClicked = false;
          this.route.goByUrl('/checkout');
        }
      });
      this.checkoutFailedSub = this.addToCart.checkoutFailed$.subscribe((res) => {
        if (res) {
          this.checkoutClicked = false;
        }
      });
    }
  }

  open(content): void {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getUserAccountData(userId): void {
    this.userAccountDetailsSub = this.addToCart.userAccountDetails$.subscribe((userDataget) => {
      if (userDataget) {
        this.usergetDataObj = userDataget.res;
        this.getuserData = userDataget.res.shippingAddresses;
        this.searchedClientList = userDataget.res.shippingAddresses;
        this.orderBlock = userDataget?.res?.orderBlockFlag ? true : false;
        if (this.orderBlock) {
          this.showDangerAlert = true;
        }
        this.changeLocation = this.getuserData?.length <= 1 ? false : true;
        this.cdr.detectChanges();
      }
    });
  }

  onOptionsSelected(data) {
    this.listSelectedData = data;
  }

  onSubmit(): void {
    if (this.listSelectedData != undefined) {
      this.selectedData = this.listSelectedData;
      this.addToCart.saveLocation(this.selectedData?.unitID,
        this.cartId, this.userAccountgetData?.userName);
      this.changedLocSub = this.addToCart.changedLocation$.subscribe((res) => {
        if (res) {
          this.addToCart.removedEntries(this.cartId, this.userAccountgetData?.userName);
          this.removeEntriesMapping();
        }
      });
    }
  }
  SearchClientName(data: any) {
    if (data.length > 0) {
      this.searchedClientList = this.getuserData.filter(x =>
        (x.companyName != undefined && x.companyName.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.unitID != undefined && x.unitID.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.line1 != undefined && x.line1.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.line2 != undefined && x.line2.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.town != undefined && x.town.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.postalCode != undefined && x.postalCode.toLowerCase().indexOf(data.toLowerCase()) >= 0))

    }
    else {
      this.searchedClientList = this.getuserData;

    }
  }
  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.addToCart.saveCartDetails(this.saveCartName, this.cartId);
      this.saveCartInvalidSub = this.addToCart.invalidCartName$.subscribe((res) => {
        if (res) {
          for (const error of res?.error?.errors) {
            this.errorMsg = error?.message;
          }
        }
      });
      this.saveCartSuccessSub = this.addToCart.saveCartSuccess$.subscribe((res) => {
        if (res) {
          if (this.savePopover) {
            this.savePopover.close();
          }
          this.errorMsg = "";
        }
      });
    } else {
      if (this.saveCartName) {
        this.errorMsg = "Save cart name is not valid.";
      } else {
        this.errorMsg = "Cart name cannot be blank";
      }
    }
  }
  exportXls() {
    const cartProducts = [];
    if (!this.cartContainsWholesalerProducts) {
      for (const entries of this.productEntries) {
        cartProducts.push({
          'Product No.': entries?.product?.code,
          'Product Name': entries?.product?.name,
          'Quantity': entries?.quantity,
          'Location': this.selectedData?.unitID + ')' + this.selectedData?.line1 + ', ' + this.selectedData?.line2 + ', ' + this.selectedData?.town + ', ' + this.selectedData?.postalCode
        });
      }
    } else {
      for (const entries of this.productEntries) {
        cartProducts.push({
          'Product No.': entries?.product?.code,
          'Product Name': entries?.product?.name,
          'Quantity': entries?.quantity,
          'Location': this.selectedData?.unitID + ')' + this.selectedData?.line1 + ', ' + this.selectedData?.line2 + ', ' + this.selectedData?.town + ', ' + this.selectedData?.postalCode,
          'WHS': entries?.wholesalerDisplayName
        });
      }
    }
    this.addToCart.downloadXls(cartProducts, 'Cart');
  }
  omit_special_char(event) {
    this.errorMsg = "";
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }
  setDeliveryDate() {
    this.minDeliveryDate.setDate(this.minDeliveryDate.getDate());
    if ((this.minDeliveryDate.getDay() === 5) || (this.minDeliveryDate.getDay() === 6)) {
      for (let i = 1; i <= 3; i++) {
        this.minDeliveryDate.setDate(this.minDeliveryDate.getDate() + 1);
        if ((this.minDeliveryDate.getDay() !== 5) && (this.minDeliveryDate.getDay() !== 6)) {
          break;
        }
      }
    }
    this.lastDayofMonthDelivery = new Date(this.minDeliveryDate.getFullYear(), this.minDeliveryDate.getMonth() + 1, 0);
    this.maxDeliveryDate = this.lastDayofMonthDelivery;

    this.deliveryDate = this.minDeliveryDate;
  }
  setPickupDate() {
    if (this.currentTime < 12) {
      // Date Set
      this.minPickupDate.setDate(this.minPickupDate.getDate());
      if (this.sessionStorage.getItem('accountType') === 'DIRECT') {
        if ((this.minPickupDate.getDay() === 5) || (this.minPickupDate.getDay() === 6)) {
          for (let i = 1; i <= 3; i++) {
            this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
            if ((this.minPickupDate.getDay() !== 5) && (this.minPickupDate.getDay() !== 6)) {
              break;
            }
          }
        }
      }
      this.lastDayofMonthPickup = new Date(this.minPickupDate.getFullYear(), this.minPickupDate.getMonth() + 1, 0);
      this.maxPickupDate = this.lastDayofMonthPickup;

    } else {
      this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
      if (this.sessionStorage.getItem('accountType') === 'DIRECT') {
        if ((this.minPickupDate.getDay() === 5) || (this.minPickupDate.getDay() === 6)) {
          for (let i = 1; i <= 3; i++) {
            this.minPickupDate.setDate(this.minPickupDate.getDate() + 1);
            if ((this.minPickupDate.getDay() !== 5) && (this.minPickupDate.getDay() !== 6)) {
              break;
            }
          }
        }
      }
      this.lastDayofMonthPickup = new Date(this.minPickupDate.getFullYear(), this.minPickupDate.getMonth() + 1, 0);
      this.maxPickupDate = this.lastDayofMonthPickup;

    }
    this.pickupDate = this.minPickupDate;
  }
  setPickupTime() {
    if (this.currentTime < 10) {
      this.timeFormat = this.pickupTimeFormat.slice(3);
    } else if (this.currentTime === 10) {
      this.timeFormat = this.pickupTimeFormat.slice(4);
    } else if (this.currentTime === 11) {
      this.timeFormat = this.pickupTimeFormat.slice(6);
    } else if (this.currentTime > 11 && this.currentTime < 17) {
      this.timeFormat = this.pickupTimeFormat.slice(0);
    } else if (this.currentTime > 16) {
      this.timeFormat = this.pickupTimeFormat.slice(3);
    }
    this.selectedTime = _.first(this.timeFormat);
  }

  closeDisclaimerAlert() {
    this.showDisclaimer = false;
  }

  closeDirectProductsDisclaimerAlert() {
    this.showDirectProductsDisclaimer = false;
  }

  ngOnDestroy() {
    this.prodAddedSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.removeEntriesSub?.unsubscribe();
    this.deleteCartSub?.unsubscribe();
    this.cartDetailsSub?.unsubscribe();
    this.checkoutDetailsSub?.unsubscribe();
    this.checkoutFailedSub?.unsubscribe();
    this.userAccountDetailsSub?.unsubscribe();
    this.changedLocSub?.unsubscribe();
    this.saveCartInvalidSub?.unsubscribe();
    this.saveCartSuccessSub?.unsubscribe();
    this.plpMiniCartSub?.unsubscribe();
    this.userDataSub?.unsubscribe();
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
  }
}
