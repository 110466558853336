import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { FaqTitleModule } from '../shared/modules/faq-title/faq-title.module';
import { MainPipeModule } from '../shared/pipes/mainpipe.module';
import { AccordianAnswersComponent } from './accordian-answers/accordian-answers.component';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [FaqComponent, AccordianAnswersComponent],
  imports: [
    CommonModule,
    NgbModule,
    FaqTitleModule,
    NgbAccordionModule,
    MainPipeModule,
    I18nModule,
  ],
  providers: [
    provideOutlet({
      id: 'FAQ-left',
      position: OutletPosition.REPLACE,
      component: FaqComponent,
    }),
  ],
})
export class FaqModule {}
