import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsmAuthStorageService, CsAgentAuthService } from '@spartacus/asm/root';
import {
  AuthActions,
  AuthService,
  AuthToken,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { VscaOAuthLibWrapperService } from '../../../../../../core/auth/user-auth/service/vsca-oauth-lib-wrapper.service';

/**
 * Auth service for CS agent. Useful to login/logout agent, start emulation
 * or get information about the status of emulation.
 */
@Injectable({
  providedIn: 'root',
})
export class SanofiCsAgentAuthService extends CsAgentAuthService {
  constructor(
    protected authService: AuthService,
    protected authStorageService: AsmAuthStorageService,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: VscaOAuthLibWrapperService,
    protected store: Store,
    protected userProfileFacade: UserProfileFacade,
    protected userAccountFacade: UserAccountFacade
  ) {
    super(
      authService,
      authStorageService,
      userIdService,
      oAuthLibWrapperService,
      store,
      // userProfileFacade,
      userAccountFacade
    );
  }

  /**
   * Loads access token for a customer support agent.
   * @param userId
   * @param password
   */
  async authorizeCustomerSupportAgent(
    userId: string,
    password: string
  ): Promise<void> {
    let userToken: AuthToken | undefined;
    this.authStorageService
      .getToken()
      .subscribe((token) => (userToken = token))
      .unsubscribe();

    this.authStorageService.switchTokenTargetToCSAgent();
    try {
      await this.oAuthLibWrapperService.vscaAuthorizeWithPasswordFlowForbaseSiteId(
        userId,
        password,
        'dz'
      );
      // Start emulation for currently logged in user
      let customerId: string | undefined;
      this.userProfileFacade
        .get()
        .subscribe((user) => (customerId = user?.customerId))
        .unsubscribe();
      this.store.dispatch(new AuthActions.Logout());

      if (customerId !== undefined && userToken !== undefined) {
        // OCC specific user id handling. Customize when implementing different backend
        this.userIdService.setUserId(customerId);
        this.authStorageService.setEmulatedUserToken(userToken);
        this.store.dispatch(new AuthActions.Login());
      } else {
        // When we can't get the customerId just end all current sessions
        this.userIdService.setUserId(OCC_USER_ID_ANONYMOUS);
        this.authStorageService.clearEmulatedUserToken();
      }
    } catch {
      this.authStorageService.switchTokenTargetToUser();
    }
  }
}
