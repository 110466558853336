<section (mouseleave)="closepop()" class="cart-icons" id="miniCart">
  <a
    [ngbPopover]="popTitleSaveSuccess"
    #s1="ngbPopover"
    triggers="manual"
    placement="bottom"
    popoverClass="savedcartsuccess-msg"
    (click)="s1.close()"
  >
    <img
      class="pdf-icon save-cart-icon"
      src="../../assets/icons/saved-orders.png"
      alt=""
      (click)="navigateTominiSavedOrder()"
  /></a>
  <a class="import-cart">
    <img
      class="pdf-icon save-cart-icon"
      src="../../assets/icons/icon_action_import_excel_default.png"
      alt=""
      (click)="navigateToBulkImport()"
  /></a>
  <a
    class="clear-cart"
    *ngIf="isEmpty && !isFlu"
    [ngbPopover]="popContentEmpty"
    [popoverTitle]="popTitleEmpty"
    (mouseenter)="p1.open()"
    #p1="ngbPopover"
    placement="bottom"
    (click)="toggleWithGreeting(p1)"
  >
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="" />
  </a>
  <a
    class="clear-cart"
    *ngIf="!isEmpty && !isFlu"
    [ngbPopover]="popContentStd"
    [popoverTitle]="popTitleStd"
    popoverClass="minicart-std-popover"
    (mouseenter)="p2.open()"
    #p2="ngbPopover"
    [placement]="miniCardPopoverPosition"
    (click)="toggleWithGreeting(p2)"
  >
    <img
      class="pdf-icon"
      src="../../assets/icons/cart.png"
      alt=""
      [ngbPopover]="popTitleSessionCart"
      #c1="ngbPopover"
      triggers="manual"
      placement="bottom"
      popoverClass="itemincart-success-msg"
      (click)="c1.close()"
      container="body"
    />
    <p class="cartvalue">{{ quantityDisplay }}</p>
  </a>
  <a
    class="clear-cart"
    *ngIf="!isEmptyFlu && isFlu"
    [ngbPopover]="popContentFlu"
    [popoverTitle]="popTitleFlu"
    (mouseenter)="p4.open()"
    #p4="ngbPopover"
    placement="bottom"
    (click)="toggleWithGreeting(p4)"
  >
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="" />
    <p class="cartvalue">{{ quantityDisplayFlu }}</p>
  </a>
  <a
    class="clear-cart"
    *ngIf="isEmptyFlu && isFlu"
    [ngbPopover]="popContentEmpty"
    [popoverTitle]="popTitleEmpty"
    (mouseenter)="p1.open()"
    #p1="ngbPopover"
    placement="bottom"
    (click)="toggleWithGreeting(p1)"
  >
    <img class="pdf-icon" src="../../assets/icons/cart.png" alt="" />
  </a>
</section>
<section class="cart-popover">
  <ng-template #popTitleEmpty>
    <div class="minicart-header">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{ "addToCartItems.emptycart" | cxTranslate }}
    </div>
  </ng-template>
  <ng-template #popContentEmpty>
    <div class="miniCart-listitem">
      <ul>
        <li>
          <a title="Order products" (click)="navigateToPdp()">{{
            "addToCartItems.emptyOrderProduct" | cxTranslate
          }}</a>
        </li>
        <li>
          <a title="" (click)="navigateToSavedOrder()">{{
            "addToCartItems.emptySaveOrders" | cxTranslate
          }}</a>
        </li>
      </ul>
    </div>
  </ng-template>
</section>

<section class="cart-popover">
  <ng-template #popTitleStd>
    <div class="minicart-header">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{ "addToCartItems.orderSubtotal" | cxTranslate }}
      {{ stdCartEntries?.subTotal?.value | localCurrency }}
    </div>
  </ng-template>
  <ng-template #popContentStd>
    <section class="cart-prod-list">
      <section class="unit-numbers">
        <span class="">
          {{ totalQuantity }} {{ "addToCartItems.units" | cxTranslate }}
        </span>
      </section>
      <section class="cart-details-minicart">
        <ul
          class="cart-prod-details"
          *ngFor="let productentry of productEntries.slice().reverse()"
        >
          <li>
            <a
              class="miniCart-prod-details"
              (click)="navigatetoProduct(productentry?.product?.code)"
            >
              <img
                *ngIf="productentry?.product?.images != null"
                class="flu-poster img_loaded"
                src="{{ productentry.PRIMARY?.url }}"
                alt="" />
              <img
                *ngIf="productentry?.product?.images == null"
                [src]="fallbackImage"
                alt=""
                class="flu-poster img_loaded" />

              <span
                class="cart-prod-title"
                [innerHTML]="productentry?.product?.name"
              ></span
            ></a>
            <span class="minicart-prod-doses">{{
              productentry?.quantity
            }}</span>
            <p class="miniCart-price">
              {{ productentry?.basePrice?.value | localCurrency }}
              {{ "plp.cad" | cxTranslate }}
            </p>
          </li>
        </ul>
      </section>
      <section class="cart-footer savepopover">
        <a role="presentation" data-di-id="di-id-c676ac20-ae73fdd6">
          <button
            type="button"
            (click)="navigateToCart()"
            class="btn btn-default blue-button"
          >
            {{ "addToCartItems.viewcart" | cxTranslate }}
          </button>
        </a>
        <button
          type="button"
          class="btn btn-default white-button"
          [ngbPopover]="popSaveContent"
          [popoverTitle]="popSaveTitle"
          triggers="manual"
          #s3="ngbPopover"
          placement="top"
          popoverClass="savedordermini-popover"
          (click)="toggle(s3)"
        >
          {{ "addToCartItems.savecart" | cxTranslate }}
        </button>
        <ng-template #popSaveTitle
          ><span class="minicart-savepopover">{{
            "addToCartItems.saveOrderTitle" | cxTranslate
          }}</span>
        </ng-template>
        <ng-template #popSaveContent>
          <input
            type="text"
            class="save-order save-order-mini"
            #name="ngModel"
            required
            [(ngModel)]="saveCartName"
            (keypress)="omit_special_char($event)"
            maxlength="30"
          />
          <span *ngIf="name.errors?.required" class="common-error required">{{
            errorMsg
          }}</span>
          <span class="common-error required">{{ errorMsg }}</span>
          <button
            type="button"
            class="popover-close btn cancel-white-button popover-cancel-button"
            (click)="toggle(s3)"
          >
            {{ "addToCartItems.cancel" | cxTranslate }}
          </button>
          <button
            class="btn save-blue-button popover-cancel-button"
            type="submit"
            data-di-id="di-id-3064f17d-e97cc628"
            (click)="saveOrder()"
          >
            {{ "addToCartItems.save" | cxTranslate }}
          </button>
        </ng-template>
      </section>
    </section>
  </ng-template>
</section>

<section class="cart-popover">
  <ng-template #popTitleFlu>
    <div class="minicart-header">
      <span class="mini-triangle"></span>
      <span class="mini-cart-title"></span>
      {{ "addToCartItems.orderSubtotal" | cxTranslate }}
      {{ fluCartEntries?.subTotal?.value | localCurrency }}
      {{ fluCartEntries?.subTotal?.currencyIso }}
    </div>
  </ng-template>
  <ng-template #popContentFlu>
    <section class="cart-prod-list">
      <section class="unit-numbers">
        <span class=""
          >{{ totalQuantityFlu }}
          {{ "addToCartItems.units" | cxTranslate }}</span
        >
      </section>
      <section class="cart-details-minicart">
        <ul
          class="cart-prod-details"
          *ngFor="let productentry of productFluEntries"
        >
          <li>
            <a (click)="navigatetoProduct(productentry?.product?.code)">
              <span class="miniCart-prod-details">
                <img
                  *ngIf="productentry?.product?.images != null"
                  class="flu-poster img_loaded"
                  src="{{ productentry.PRIMARY.url }}"
                  alt=""
                />
                <img
                  *ngIf="productentry?.product?.images == null"
                  [src]="fallbackImage"
                  alt=""
                  class="flu-poster img_loaded"
                />
              </span>
              <span
                class="cart-prod-title"
                [innerHTML]="productentry?.product?.name"
              ></span
            ></a>
            <span class="minicart-prod-doses">{{
              productentry?.quantity
            }}</span>
            <p class="miniCart-price flu-minicart-price">
              {{ productentry?.basePrice?.value | localCurrency }}
              {{ "plp.cad" | cxTranslate }}
            </p>
          </li>
        </ul>
      </section>
      <section class="cart-footer savepopover">
        <a role="presentation" data-di-id="di-id-c676ac20-ae73fdd6">
          <button
            type="button"
            (click)="navigateToFlufromCart()"
            class="btn btn-default blue-button"
          >
            {{ "addToCartItems.viewcart" | cxTranslate }}
          </button>
        </a>
      </section>
    </section>
  </ng-template>
</section>

<section class="added-success-container">
  <ng-template #popTitleSuccess>
    <div class="cart-value-added">
      <img
        src="../../../assets/icons/confirmation-positive-green.png"
        class="positive-green-icon"
        alt="success"
      />
      <span class="confirmation-itemsadded"
        >{{ itemAddedQuantity }}
        {{ "addToCartItems.addEntrySuccess" | cxTranslate }}</span
      >
    </div>
  </ng-template>
</section>
<ng-template #popTitleSaveSuccess>
  <div class="cart-value-added">
    <img
      src="../../../assets/icons/confirmation-positive-green.png"
      class="positive-green-icon"
      alt="success"
    />
    <span class="confirmation-itemsadded"
      >{{ cartSavedSuccess }}
      {{ "addToCartItems.savesuccess" | cxTranslate }}</span
    >
  </div>
</ng-template>

<ng-template #popMinicartItems>
  <div class="cart-value-added">
    <img
      src="../../../assets/icons/confirmation-positive-green.png"
      class="positive-green-icon"
      alt="success"
    />
    <span class="confirmation-itemsadded"
      >{{ quantityDisplay }}
      {{ "addToCartItems.sessionCartMsg" | cxTranslate }}</span
    >
  </div>
</ng-template>
<ng-template #popTitleSessionCart>
  <div class="cart-value-added">
    <img
      src="../../../assets/icons/info.png"
      class="positive-green-icon"
      alt="info"
    />
    <span class="confirmation-itemsadded"
      >{{ quantityDisplay }}
      {{ "addToCartItems.sessionCartMsg" | cxTranslate }}</span
    >
  </div>
</ng-template>
