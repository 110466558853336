import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalCurrencyPipe } from './localcurency';
import { SafeHtmlPipe } from './safehtml';
import { SortPipe } from './sort.pipe';

@NgModule({
  declarations: [LocalCurrencyPipe, SafeHtmlPipe, SortPipe],
  imports: [CommonModule],
  exports: [LocalCurrencyPipe, SafeHtmlPipe, SortPipe],
})
export class MainPipeModule {}
export { SafeHtmlPipe };
