import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';

@Injectable({
  providedIn: 'root',
})
export class OpenStatementsService {
  public reloadComponent = new Subject<boolean>();
  public loadStatements = new Subject<any>();
  public userAccountIdDetails = new Subject<any>();
  public excelData = new BehaviorSubject<any>(null);
  excelData$ = this.excelData.asObservable();

  constructor(
    private readonly baseService: BaseService,
    public excelService: ExcelDownloadService
  ) {}

  public getStatements(params): Observable<any> {
    return this.baseService.get(config.GET_OPEN_STATEMENTS, params);
  }

  public getAllStatements(params): Observable<any> {
    return this.baseService.get(config.GET_ALL_STATEMENTS_DATA_RANGE, params);
  }
  public getAccountId() {
    this.baseService.get(config.GET_ACCOUNT_ID).subscribe((res) => {
      if (res !== null && res !== undefined && res?.length !== 0) {
        this.userAccountIdDetails.next(res);
      }
    });
  }

  public downloadXls(data, name) {
    this.excelService.exportAsExcelFile(data, name, true);
  }
  public getExcelData(params) {
    this.baseService
      .downloadFile(`/my-account/statementsDisplay/xls`, params)
      .subscribe((response) => {
        const blob = new Blob([response.body], { type: 'text/xlsx' });
        saveAs(blob, 'statements.xlsx');
      });
  }
}
