import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  provideConfig,
} from '@spartacus/core';
import { AddCardModule } from '../shared/modules/add-card/add-card.module';
import { ProfileAdBannerModule } from '../shared/modules/profile-ad-Banner/profile-ad-Banner.module';
import { AccountComponent } from './account/account.component';
import { CustomProfileAndPreferencesComponent } from './custom-profile-and-preferences/custom-profile-and-preferences.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { PaymentComponent } from './payment/payment.component';
import { UserComponent } from './user/user.component';

@NgModule({
  declarations: [
    CustomProfileAndPreferencesComponent,
    MyAccountComponent,
    UserComponent,
    AccountComponent,
    PaymentComponent,
  ],

  imports: [
    CommonModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserModule,
    I18nModule,
    FormsModule,
    AddCardModule,
    ProfileAdBannerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountProfilePreferenceComponent: {
          component: CustomProfileAndPreferencesComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
  ],

  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class CustomProfileAndPreferencesModule {}
